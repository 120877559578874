import type { ExternalLinks } from '.'

export const QASA_EXTERNAL_LINKS: ExternalLinks = {
  helpCenterUrl: 'https://support.qasa.se/',
  termsAndConditionsUrl: 'https://support.qasa.se/hc/articles/360018470638',
  requestSupportUrl: 'https://support.qasa.se/hc/requests/new?ticket_form_id=7454891211665',
  insuranceClaim: 'https://external.omocom.se/claimsform',
  insuranceSupportUrl: 'https://support.qasa.se/hc/articles/12668192470289',
  HedvigInsuranceAppUrl: 'https://www.hedvig.com/hedvig-appen',
  HedvigInsuranceInfoUrl: 'https://www.hedvig.com/partner/qasa-kom-igang',
  rentGuaranteSupportUrl: 'https://support.qasa.se/hc/articles/115009719628',
  tenantBaseFeeSupportUrl: 'https://support.qasa.se/hc/articles/115009756808',
  vacationTenantBaseFeeSupportUrl: 'https://support.qasa.se/hc/articles/115009756808',
  depositFreeSupportUrl: 'https://support.qasa.se/hc/articles/360020583897',
  privacyPolicyUrl:
    'https://qasa.s3-eu-west-1.amazonaws.com/uploads/terms/Personuppgiftspolicy+Qasa+190906.pdf',
  personalDataSupportUrl: 'https://support.qasa.se/hc/articles/360018470638',
  creditCheckSupportUrl: 'https://support.qasa.se/hc/articles/115009759328',
  incomeVerificationSupportUrl: 'https://support.qasa.se/hc/articles/115009759328',
  vacationMoreInfoUrl: 'https://www.blocket.se/tips-och-guider/hus-och-lagenhet/hyr-sommarstuga',
  vacationTermsAndConditionsUrl: 'https://support.qasa.se/hc/articles/360018470638',
  sortingPolicyUrl: 'https://support.qasa.se/hc/articles/17763803104657',
  landlordContractTerminationSupportUrl: 'https://support.qasa.se/hc/articles/115009540267',
  tenantContractTerminationSupportUrl: 'https://support.qasa.se/hc/articles/115009584727',
  securitySupportUrl: 'https://support.qasa.se/hc/articles/360023229291',
  settingRentSupportUrl: 'https://support.qasa.se/hc/articles/115010448107',
  shortcutMoreInfo: 'https://support.qasa.se/hc/articles/13159619946641',
  press: 'https://support.qasa.se/hc/articles/360000479128-Press',
  forCompaniesSupportUrl: 'https://support.qasa.se/hc/articles/17495920242065',
  instantSignSupportUrl: 'https://support.qasa.se/hc/articles/20163059077393',
  pricing: 'https://support.qasa.se/hc/articles/22955620204433',
  infoEmail: 'info@qasa.se',
  publishListingsSupportUrl: 'https://support.qasa.se/hc/articles/17476833835281',
  landlordOfferingSupportUrl: 'https://support.qasa.se/hc/articles/115009721208',
  landlordRentPaymentIssuesSupportUrl: 'https://support.qasa.se/hc/articles/360044996872',
  keyExchangeReceiptUrl: 'https://support.qasa.se/hc/article_attachments/17494819815057',
  inventoryListUrl: 'https://support.qasa.se/hc/article_attachments/19851116824849',
  depositHandlingSupportUrl: 'https://support.qasa.se/hc/articles/115009712988',
  connectedAccounts: 'https://support.qasa.se/hc/articles/25735065112209',
  homeqPresentation: 'https://support.qasa.se/hc/articles/27763824014737',
  firsthandHowDoesItWork: 'https://support.qasa.se/hc/sv/articles/27763212415761',
  queuePoints: 'https://support.qasa.se/hc/sv/articles/27763664511249',
  firsthandApplicationProcess: 'https://support.qasa.se/hc/sv/articles/27890296158865',
  firsthandApplicationAttachments: 'https://support.qasa.se/hc/sv/articles/27890189099793',
}
