import i18next from 'i18next'
import type { FlatNamespace, KeyPrefix, i18n } from 'i18next'
import { createContext, useContext, useEffect } from 'react'
import type { FallbackNs, UseTranslationOptions } from 'react-i18next'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTranslation } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'

import { CURRENT_PLATFORM } from '../../env'
import { langs } from '../../translations'

type I18nextContextType = {
  i18n: i18n
}

// @ts-ignore
const i18nextContext = createContext<I18nextContextType>(null)

const getCurrentLanguage = () => window.location.pathname.split('/')[1] as 'en' | 'sv' | 'fr' | 'fi' | 'nb'

const IS_SERVER_SIDE = typeof window === 'undefined'

const i18nInstance = i18next.createInstance()

i18nInstance
  .use({
    type: 'languageDetector',
    detect: () => {
      if (IS_SERVER_SIDE || CURRENT_PLATFORM === 'native') {
        return undefined
      }

      return getCurrentLanguage()
    },
  })
  .use(
    resourcesToBackend(async (language: keyof typeof langs, namespace: string) => {
      const resources = await langs[language]()

      const allNamespaces = Object.keys(resources)

      // Ensure we load all namespaces for the given language.
      // Preferable we'd only like to load the given namespace, but
      // since we've right now have so many source files we'd trigger
      // Suspense boundaries all over the place.
      allNamespaces.forEach((ns) => {
        if (!i18nInstance.hasResourceBundle(language, ns)) {
          i18nInstance.addResourceBundle(language, ns, resources[ns])
        }
      })

      return resources[namespace]
    }),
  )
  .init({
    lng: undefined,
    fallbackLng: 'dev',
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    ns: ['common'],
    defaultNS: 'common',
    fallbackNS: 'common',
    compatibilityJSON: 'v3',
    preload: IS_SERVER_SIDE ? ['dev', 'en', 'sv', 'fr', 'fi', 'nb'] : [],
  })

type Props = {
  currentLanguage: string
  children: React.ReactNode
}

function I18nextProvider({ currentLanguage, children }: Props) {
  if (IS_SERVER_SIDE && i18nInstance.language !== currentLanguage) {
    i18nInstance.changeLanguage(currentLanguage)
  }

  useEffect(() => {
    if (i18nInstance.language === currentLanguage) return
    i18nInstance.changeLanguage(currentLanguage)
  }, [currentLanguage])

  return <i18nextContext.Provider value={{ i18n: i18nInstance }}>{children}</i18nextContext.Provider>
}

const useAppTranslation = <
  TNamespace extends
    | FlatNamespace
    | readonly [(FlatNamespace | undefined)?, ...FlatNamespace[]]
    | undefined = undefined,
  TKeyPrefix extends KeyPrefix<FallbackNs<TNamespace>> = undefined,
>(
  ns?: TNamespace,
  options?: UseTranslationOptions<TKeyPrefix>,
) => {
  const { i18n } = useContext(i18nextContext)

  return useTranslation(ns, { i18n, ...options })
}
export { I18nextProvider, useAppTranslation }
