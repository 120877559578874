import { PlatformEnum, type MarketNameEnum } from '@qasa/graphql'

import { useRegionConfig } from '../use-region-config'

import { BLOCKET_EXTERNAL_LINKS } from './blocket'
import { QASA_EXTERNAL_LINKS } from './qasa'
import { QASA_FINLAND_EXTERNAL_LINKS } from './qasa_finland'
import { QASA_FRANCE_EXTERNAL_LINKS } from './qasa_france'

type BrandType = 'qasa' | 'blocket' | 'qasa_finland' | 'qasa_france' | 'dotcom'

export type ExternalLinks = {
  helpCenterUrl: string
  requestSupportUrl?: string
  insuranceClaim?: string
  termsAndConditionsUrl: string
  vacationTermsAndConditionsUrl?: string
  insuranceSupportUrl?: string
  HedvigInsuranceAppUrl?: string
  HedvigInsuranceInfoUrl?: string
  tenantBaseFeeSupportUrl?: string
  vacationTenantBaseFeeSupportUrl?: string
  rentGuaranteSupportUrl?: string
  depositFreeSupportUrl?: string
  depositHandlingSupportUrl?: string
  privacyPolicyUrl?: string
  personalDataSupportUrl?: string
  creditCheckSupportUrl?: string
  incomeVerificationSupportUrl?: string
  schibstedAccountSettingsUrl?: string
  vacationMoreInfoUrl?: string
  pricing?: string
  sortingPolicyUrl?: string
  landlordContractTerminationSupportUrl?: string
  tenantContractTerminationSupportUrl?: string
  landlordOfferingSupportUrl?: string
  securitySupportUrl?: string
  shortcutMoreInfo?: string
  settingRentSupportUrl?: string
  press?: string
  legalMentions?: string
  ownerJourney?: string
  partnerShip?: string
  howDoesItWork?: string
  services?: string
  insuranceGuarantee?: string
  instantSignSupportUrl?: string
  oikotiePublishListingUrl?: string
  forCompaniesSupportUrl?: string
  insuranceTerms?: string
  leaseTakeoverForm?: string
  recontactForm?: string
  infoEmail: string
  publishListingsSupportUrl?: string
  landlordRentPaymentIssuesSupportUrl?: string
  keyExchangeReceiptUrl?: string
  inventoryListUrl?: string
  connectedAccounts?: string
  homeqPresentation?: string
  firsthandHowDoesItWork?: string
  queuePoints?: string
  firsthandApplicationProcess?: string
  firsthandApplicationAttachments?: string
}

const QASA_DOTCOM_EXTERNAL_LINKS = {
  ...QASA_EXTERNAL_LINKS,
  termsAndConditionsUrl: 'https://qasa.com/legal-terms-and-conditions',
}

const externalLinks: Record<BrandType, ExternalLinks> = {
  qasa: QASA_EXTERNAL_LINKS,
  qasa_finland: QASA_FINLAND_EXTERNAL_LINKS,
  blocket: BLOCKET_EXTERNAL_LINKS,
  qasa_france: QASA_FRANCE_EXTERNAL_LINKS,
  dotcom: QASA_DOTCOM_EXTERNAL_LINKS,
}

export const EXTERNAL_LINKS_BY_MARKET: Record<MarketNameEnum, ExternalLinks> = {
  sweden: QASA_EXTERNAL_LINKS,
  finland: QASA_FINLAND_EXTERNAL_LINKS,
  france: QASA_FRANCE_EXTERNAL_LINKS,
  norway: QASA_EXTERNAL_LINKS,
}

// NOTE: Used to get the correct external links for the current market.
// However since blocket still live side by side with qasa, we need to check if the platform is blocket
// to get the correct external links for blocket.
export const useExternalLinks = ({ marketName }: { marketName?: MarketNameEnum } = {}) => {
  const platform = useRegionConfig().platform
  const isBlocket = platform === PlatformEnum.blocket

  if (isBlocket) {
    return BLOCKET_EXTERNAL_LINKS
  }

  if (!marketName) {
    return platform ? externalLinks[platform as BrandType] : externalLinks.dotcom
  }

  return EXTERNAL_LINKS_BY_MARKET[marketName]
}

export {
  BLOCKET_EXTERNAL_LINKS,
  QASA_EXTERNAL_LINKS,
  QASA_FINLAND_EXTERNAL_LINKS,
  QASA_FRANCE_EXTERNAL_LINKS,
  QASA_DOTCOM_EXTERNAL_LINKS,
}
