import styled from '@emotion/styled'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import type { ComponentProps } from 'react'
import { XIcon, IconButton, Heading } from '@qasa/ui'
import { theme } from '@qasa/qds-ui'

import { useAppTranslation } from '../../../contexts/i18next'

import { OVERLAY_TRANSITION_STYLES } from './dialog-transitions'

export const DialogOverlay = styled(DialogPrimitive.Overlay)(
  {
    backgroundColor: theme.colors.core.blackAlpha20,
    position: 'fixed',
    inset: 0,
    // NOTE: zIndex needs to be the same as the dialog content to ensure overlay is visible when opened from another dialog
    zIndex: theme.zIndices.modal,
  },
  OVERLAY_TRANSITION_STYLES,
)
// NOTE: the optional side padding was added as a temporary solution until we finalise how we want the dialog spacing to work
// this way the dialog header can be styled the same as the dialog body and footer
// defaults to false to avoid breaking existing dialogs - Neza, 3.1.2024
export const DialogHeader = styled.header<{ hasSidePadding?: boolean }>(({ hasSidePadding = false }) => ({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  minHeight: theme.sizes['16x'],
  paddingTop: theme.sizes['12x'],
  paddingBottom: theme.sizes['2x'],
  paddingLeft: hasSidePadding ? theme.sizes['6x'] : 0,
  paddingRight: hasSidePadding ? theme.sizes['6x'] : 0,
  [theme.mediaQueries.mdUp]: {
    paddingBottom: theme.sizes['4x'],
    paddingLeft: hasSidePadding ? theme.sizes['12x'] : 0,
    paddingRight: hasSidePadding ? theme.sizes['12x'] : 0,
  },
}))

export function DialogTitle({ children, ...restProps }: ComponentProps<typeof Heading>) {
  return (
    <DialogPrimitive.Title asChild>
      <Heading size="lg" {...restProps}>
        {children}
      </Heading>
    </DialogPrimitive.Title>
  )
}

export const DialogBody = styled.div<{ hasPadding?: boolean }>(({ hasPadding = true }) => ({
  flex: 1,
  paddingLeft: hasPadding ? theme.sizes['6x'] : 0,
  paddingRight: hasPadding ? theme.sizes['6x'] : 0,
  paddingBottom: hasPadding ? theme.sizes['6x'] : 0,
  overflowY: 'auto',
  [theme.mediaQueries.mdUp]: {
    paddingBottom: hasPadding ? theme.sizes['8x'] : 0,
    paddingLeft: hasPadding ? theme.sizes['12x'] : 0,
    paddingRight: hasPadding ? theme.sizes['12x'] : 0,
  },
}))

export const DialogFooter = styled.footer<{ hasSidePadding?: boolean; hasBorder?: boolean }>(
  ({ hasSidePadding = false, hasBorder = true }) => ({
    minHeight: theme.sizes['16x'],
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.sizes['4x'],
    paddingBottom: theme.sizes['4x'],
    paddingLeft: hasSidePadding ? theme.sizes['6x'] : 0,
    paddingRight: hasSidePadding ? theme.sizes['6x'] : 0,
    borderTop: hasBorder ? `1px solid ${theme.colors.border.default}` : 'none',
    [theme.mediaQueries.mdUp]: {
      paddingTop: ['8x'],
      paddingBottom: theme.sizes['4x'],
      paddingLeft: hasSidePadding ? theme.sizes['16x'] : 0,
      paddingRight: hasSidePadding ? theme.sizes['16x'] : 0,
    },
  }),
)

export const CloseButtonWrapper = styled.div({
  position: 'absolute',
  right: theme.sizes['2x'],
  top: theme.sizes['2x'],
})

export function DialogCloseButton() {
  const { t } = useAppTranslation('common_buttons')
  return (
    <DialogPrimitive.Close asChild>
      <CloseButtonWrapper>
        <IconButton icon={XIcon} label={t('close')} type="button" onPress={() => null} />
      </CloseButtonWrapper>
    </DialogPrimitive.Close>
  )
}
