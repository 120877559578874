import { theme, useBreakpointValue } from '@qasa/qds-ui'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import styled from '@emotion/styled'

import type { DialogRootProps } from '../types'

import { DIALOG_CONTENT_TRANSITION_STYLES } from './dialog-transitions'
import {
  DialogCloseButton,
  DialogFooter,
  DialogBody,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from './dialog.parts'

export const DESKTOP_SIZES = {
  sm: theme.sizes[448],
  md: theme.sizes[576],
  lg: theme.sizes[768],
  xl: theme.sizes[896],
}

type StyledContentProps = Required<Pick<DialogRootProps, 'size' | 'mode'>>

const StyledContent = styled(DialogPrimitive.Content)<StyledContentProps>(
  ({ size, mode }) => ({
    zIndex: theme.zIndices.modal,
    background: theme.colors.bg.default,
    boxShadow: theme.shadows.lg,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    outline: 'none',
    borderRadius: theme.radii.xl,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    maxHeight: '95%',
    bottom: 0,
    left: 0,
    right: 0,
    ...(mode === 'fullScreen' && { inset: 0, borderRadius: 0, maxHeight: '100%' }),
    [theme.mediaQueries.mdUp]: {
      transform: 'translate(-50%, -50%)',
      borderRadius: theme.radii.xl,
      maxHeight: size === 'md' ? '90%' : '80%',
      width: DESKTOP_SIZES[size],
      top: '50%',
      left: '50%',
      bottom: 'initial',
      right: 'initial',
      ...((size === 'lg' || size === 'xl') && {
        width: `calc(100% - ${theme.sizes['6x']} * 2)`,
        maxWidth: DESKTOP_SIZES[size],
      }),
      ...(mode === 'fullScreen' && {
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        borderRadius: 0,
      }),
    },
  }),
  DIALOG_CONTENT_TRANSITION_STYLES,
)

type Props = Pick<DialogPrimitive.DialogContentProps, 'onOpenAutoFocus'> & DialogRootProps

function DialogRoot({
  children,
  isOpen,
  size = 'md',
  mode = 'sheet',
  isCloseButtonVisible = true,
  onOpenAutoFocus,
  trigger,
  ...restProps
}: Props) {
  const isMdOrAbove = useBreakpointValue({ base: false, md: true }, { ssr: true })

  const handleOpenAutoFocus = (event: Event) => {
    // Having autofocus when the dialog opens causes a lot of
    // issues on mobile (because of the virtual keyboard) so we disable it entirely.
    if (!isMdOrAbove) {
      event.preventDefault()
      // Fixes issue with dialog not focus trapping
      ;(event.currentTarget as HTMLElement).focus()
    }
    onOpenAutoFocus && onOpenAutoFocus(event)
  }

  return (
    <DialogPrimitive.Root open={isOpen} {...restProps}>
      {trigger && <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>}
      <DialogPrimitive.Portal>
        <DialogOverlay />
        <StyledContent size={size} mode={mode} onOpenAutoFocus={handleOpenAutoFocus}>
          {children}
          {isCloseButtonVisible && <DialogCloseButton />}
        </StyledContent>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

export const Dialog = Object.assign(DialogRoot, {
  /**
   * The sticky header. Usually used in conjunction with the `Title`
   */
  Header: DialogHeader,
  /**
   * The title that labels the dialog
   */
  Title: DialogTitle,
  /**
   * Scrollable main content of dialog
   */
  Body: DialogBody,
  /**
   * The sticky footer that houses the dialog actions
   */
  Footer: DialogFooter,
})
